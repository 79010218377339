@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap&text=أبتثجحخدذرزسشصضطظعغفقكلمنهويىةلابً');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html:lang(ar) [shape='caret right'],
html:lang(ar) [shape='caret left'],
html:lang(ar) [shape='arrow right'],
html:lang(ar) [shape='arrow left'] {
    transform: scaleX(-1);
}

html,
body:not([cds-text]) {
    font-size: var(--font-size-sm);
    font-family: 'IBM Plex Sans Arabic', 'IBM Plex Sans', Inter, sans-serif !important;
    line-height: var(--cds-global-typography-body-line-height);
    color: var(--color-text-100);
}

body p:not([cds-text]) {
    font-family: 'IBM Plex Sans Arabic', sans-serif !important;

    // font-family: Inter, sans-serif !important;
}

.page-block {
    margin-inline-start: var(--surface-margin-left);
    margin-inline-end: var(--space-unit);
    margin-top: var(--space-unit);
    max-width: var(--layout-content-max-width);
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-corner {
    background-color: var(--color-scrollbar-bg);
}
::-webkit-scrollbar-thumb {
    background-color: var(--color-scrollbar-thumb);
    border: 2px solid var(--color-scrollbar-bg);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-scrollbar-thumb-hover);
}
::-webkit-scrollbar-track {
    background-color: var(--color-scrollbar-bg);
}
